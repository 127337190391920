import Article from 'components/Article';
import ArticleList from './primitives/ArticleList';
import { ReactType } from 'react';

type Props = {
  articleClass: ReactType;
  bestMatch?: boolean;
  data: Array<{
    [a: string]: any;
  }>;
  dataTest?: string;
  filter?: any;
  hidePadding?: boolean;
  search?: boolean;
  supressDeepLink?: boolean;
};

function Articles({
  bestMatch = false,
  articleClass: ArticleClass = Article,
  data = [],
  dataTest,
  filter = '',
  hidePadding = false,
  search,
  supressDeepLink,
}: Props) {
  return (
    <ArticleList data-test="article-list">
      {data.map(article => (
        <ArticleClass
          {...article}
          bestMatch={bestMatch}
          dataTest={dataTest}
          dropdownExtendDown={article.firstTrack}
          filter={filter}
          hidePadding={hidePadding}
          key={`${article.id}-${article.title}`}
          search={search}
          supressDeepLink={supressDeepLink}
        />
      ))}
    </ArticleList>
  );
}

export default Articles;
